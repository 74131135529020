import { render, staticRenderFns } from "./RePricing.vue?vue&type=template&id=a96e5e54&scoped=true&"
import script from "./RePricing.vue?vue&type=script&lang=js&"
export * from "./RePricing.vue?vue&type=script&lang=js&"
import style0 from "../Assists/Assist.css?vue&type=style&index=0&id=a96e5e54&prod&scoped=true&lang=css&"
import style1 from "../Element/custom-m-loader.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a96e5e54",
  null
  
)

export default component.exports