import { render, staticRenderFns } from "./dasboard.vue?vue&type=template&id=91d3a19c&"
import script from "./dasboard.vue?vue&type=script&lang=js&"
export * from "./dasboard.vue?vue&type=script&lang=js&"
import style0 from "./dasboard.vue?vue&type=style&index=0&id=91d3a19c&prod&lang=css&"
import style1 from "../Element/custom-m-loader.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports